import React, { Component } from "react";

export default class Landing extends Component {
 
  render() {
    
    return (
      <h1 className="Landing text-center large-text">Welcome to Be Still</h1>
      
    );
    
  }
}
