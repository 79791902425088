import React, { Component } from 'react';
import Spinner from 'react-spinkit';
import  firebase from "../reference.js";
import {isMobile, isIOS, isAndroid} from 'react-device-detect';

export default class VerifyEmail extends Component {
  state = {
    error: '',
    validCode: null,
    verifiedCode: false,
    authRef: firebase.auth(),
  }

  componentDidMount() {
    // Try to apply the email verification code.
    this.state.authRef
      .applyActionCode(this.props.actionCode)
      .then(() => {
        // Email address has been verified.
        this.setState({ validCode: true, verifiedCode: true });
      }, error => {
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
        this.setState({ error: error.message, validCode: false, verifiedCode: true });
      });
  }

  backToApp = () => {
    // if(isIOS) {
    //   window.open('secondBestill://');
    // } else if(isAndroid){
    //   window.open('org.second.bestill');
    // }
    
    // window.open('https://bestilltest.page.link/welcom');
    window.open('https://bestillapp.page.link/welcome');
    
  };
  render() {
    const {
      validCode,
      verifiedCode,
    } = this.state;

    let component;
    if (!verifiedCode) {
      component = <Spinner name="three-bounce" />;
    } else if (verifiedCode && validCode) {
      component = (
        <div className="VerifyEmail">
          <h1 className="text-center">Your email has been verified</h1>
          <p className="text-center">You can now sign in with your new account</p>
            
        {isMobile?  <button onClick={this.backToApp}>
             RETURN TO BE STILL APP
            </button>:''}
        </div>
      );
    } else if (verifiedCode && !validCode) {
      component = (
        <div className="VerifyEmail">
          <h1 className="text-center">Try verifying your email again</h1>
          <p className="error text-center">The code is invalid or has expired.</p>
        </div>
      );
    }

    return component;
  }
}
