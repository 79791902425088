import React, { Component } from "react";
import Spinner from "react-spinkit";
import firebase from "../reference.js";
import {isMobile, isIOS, isAndroid} from 'react-device-detect';

export default class RecoverEmail extends Component {
  state = {
    error: "",
    restoredEmail: "",
    resetSent: false,
    validCode: null,
    verifiedCode: false,
    db: firebase.firestore(),
    authRef: firebase.auth(),
  };

  componentDidMount() {
    // Confirm the action code is valid.
    this.state.authRef.checkActionCode(this.props.actionCode).then(
      (info) => {
        // Get the restored email address.
        const restoredEmail = info["data"]["email"];
        const fromEmail = info["data"]["fromEmail"];
        // Revert to the old email.
        this.state.authRef.applyActionCode(this.props.actionCode).then(() => {
          // Account email reverted to restoredEmail
          console.log(fromEmail);
          this.state.db
            .collection("User")
            // .where("Email", "==", fromEmail) // Dan != dan
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                console.log(doc.id, " => ", doc.data()["Email"]);
                var newEmail = doc.data()["Email"]??'';
                if (
                  newEmail.toLowerCase() === fromEmail.toLowerCase()
                )
                    this.state.db
                  .collection("User")
                  .doc(doc.id)
                  .update({ Email: restoredEmail });
              
              });
            });
          this.setState({ restoredEmail, validCode: true, verifiedCode: true });
        });
      },
      (error) => {
        // Invalid code.
        this.setState({
          error: error.message,
          validCode: false,
          verifiedCode: true,
        });
      }
    );
  }

  sendReset = () => {
    // You might also want to give the user the option to reset their password
    // in case the account was compromised:
    this.state.authRef
      .sendPasswordResetEmail(this.state.restoredEmail)
      .then(() => {
        // Password reset confirmation sent. Ask user to check their email.
        this.setState({ resetSent: true });
      });
  };

  
  backToApp = () => {
    // if(isIOS) {
    //   window.open('secondBestill://');
    // } else if(isAndroid){
    //   window.open('org.second.bestill');
    // }
    
    // window.open('https://bestilltest.page.link/welcom');
    window.open('https://bestillapp.page.link/welcome');
  };
  render() {
    const { restoredEmail, resetSent, validCode, verifiedCode } = this.state;

    let component;
    if (!verifiedCode) {
      component = <Spinner name="three-bounce" />;
    } else if (resetSent) {
      component = (
        <div className="RecoverEmail">
          <h1 className="text-center">Check your email</h1>
          <p className="text-center">
            Follow the instructions sent to <span>{restoredEmail}</span> to
            recover your password.
          </p>
        </div>
      );
    } else if (verifiedCode && validCode) {
      component = (
        <div className="RecoverEmail">
          <h1 className="text-center">Updated email address</h1>
          <p className="text-center">
            Your sign-in email address has been changed back to{" "}
            <span>{restoredEmail}</span>
          </p>
          <p className="text-center">
          If you did not change your sign-in email, it is possible someone is trying to access your account and you should change your password right away.
          </p>
          <div className="space-y"></div>
          <button onClick={this.sendReset}>
          Change my password now
          </button>
            
        {isMobile?  <button onClick={this.backToApp}>
             RETURN TO BE STILL APP
            </button>:''}
        </div>
      );
    } else if (verifiedCode && !validCode) {
      component = (
        <div className="RecoverEmail">
          <h1 className="text-center">Unable to update your email address</h1>
          <p className="text-center">
            There was a problem changing your sign-in email back.
          </p>
          <p className="error text-center">
            The code is invalid or has expired. Try again!
          </p>
        </div>
      );
    }

    return component;
  }
}
