import React, { Component } from "react";

export default class Success extends Component {
 

  render() {
    return (
      <div>
        <h1 className="text-center">Your request has been handled successfully!</h1>
      </div>
    );
  }
}
