import "./App.css";
import "./index.css";
import Success from "./components/Success";
import Action from "./components/Action";
import Landing from "./components/Landing";
import { BrowserRouter as Router,  Route } from "react-router-dom";

import logo from './assets/logo.png'; 

function App() {
  return (
    <Router>
    <div className="App">
      <div className="appbar">
        <div className="flex">
          <img
          className="logo"
          src={logo} alt="be still logo"
        />
          <div className="space-x"></div>
          <h1>Be Still</h1>
        </div>
      </div>
      <hr></hr>
      <div className="children">
      <Router>
      <Route path="/" component={Landing} />
      <Route path="/success" component={Success} />
      <Route path="/__/auth/action" component={Action} />
   
  </Router>
      </div>
    </div>
    </Router>
  );
}

export default App;
